import { styled, alpha } from '@mui/material/styles';
import MuiButton from '@mui/lab/LoadingButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import StyledBox from '../Box/StyledBox';

const Button = styled(MuiButton)(({ theme }) => ({
    border: '1px solid',
    fontWeight: 500,
    
    borderRadius: '3px',
    fontSize: '1rem',
    '&:hover': {
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        transform: 'translateY(-3px)' ,
        transition:'0.2s ease',
    },
    // '&:hover': {
    //     backgroundColor: theme.palette.secondary.dark,
    //     color: '#fff',
    //     border: '1px solid ' + theme.palette.secondary.grey,
    //     // border: 'none',
    // },

    border: 'none',
}));

const AccountFormPrimary = ({children, size, className, color, style, sx, onClick, loadingPosition, startIcon, variant, disabled, loading, type = "button"}) => (
    <StyledBox className='w-100'>
    <Button
        sx={{
            ...sx,
            backgroundColor: (theme) => theme.palette[variant].main,
            color: (theme) => theme.palette[variant].contrastText,
            '&:hover': {
                backgroundColor: (theme) => theme.palette[variant].dark,
                color: (theme) => theme.palette[variant].contrastTextDark,
            },
            '& .MuiButtonBase-root': {
                color: (theme) => theme.palette[variant].contrastText
            },
            '& .MuiCircularProgress-root': {
                color: (theme) => theme.palette[variant].contrastText
            },
            "&:disabled": {
                color: (theme) => theme.palette[variant].contrastText
            },
            opacity: disabled ? 0.5 : 1
        }}
        size={size}
        className={className}
        color={color}
        style={style}
        onClick={onClick} 
        loadingPosition={loadingPosition}
        startIcon={startIcon}
        variant={variant}
        loading={loading}
        disabled={disabled}
        type={type}
    >
        {children}
    </Button>
    </StyledBox>
)


export default AccountFormPrimary