import { useState, useEffect } from "react";

const useToggleState = (startingState = false) => {
    const [open, setOpen] = useState(startingState);

    useEffect(() => {
        return () => {
            setOpen(false);
        }
    }, [])
    

    const handleToggle = () => {
        setOpen(!open);
    }
    
    return {open, setOpen, handleToggle}
}

export default useToggleState;