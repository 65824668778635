import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import useToggleState from '../../_hooks/useToggleState';
import { useSelector } from 'react-redux';
import AccountFormPrimary from '../../Custom/Buttons/AccountFormPrimary';
import { buildAddressToString } from '../../config/helpers';
import useAuth from '../../_hooks/useAuth';
import { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import crmSizes from "../../Setup/crmSizes";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
        xs: 300,
        md: 400,
        lg: 600
    },
    bgcolor: 'background.paper',
    border: '1px solid grey',
    boxShadow: 24,
    height: 600,
    overflowY: 'auto',
    p: 4,
    ...crmSizes()
};

const OpenedModal = ({handleToggle, open}) => {

    const {linkedAccounts, authLoaders} = useSelector(state => state.authReducer);

    const {selectedAccount, handleRadioChange, submitDefaultAccount, resendVerificationLink, setSelectedAccount} = useAuth();

    useEffect(() => {
        //customer default id has been updated
        !authLoaders.getDefaultAccount && selectedAccount != null && handleToggle()
    }, [linkedAccounts.default.CustomerID])
    

    return (
       <Modal
            sx={{overflowY: 'scroll'}}
            open={open}
            onClose={handleToggle}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
        <Box sx={style}>
            <Box>
                </Box>
                <Typography id="modal-modal-title" className="fw-bold mb-2" sx={{fontSize: 18}} variant="h6" component="h1">
                    Switch Current Account
                <IconButton className="f-right" onClick={handleToggle} sx={{position: 'relative', left: '25px', bottom: '6px'}}>
                    <CloseIcon/>
                </IconButton>
                </Typography>
            <Divider sx={{width:'auto'}}/>

            <Box sx={{
                padding: '5px',
            }} className="mt-3">
                <FormControl>
                    {/* <FormLabel className="text-start">Choose your default account</FormLabel> */}
                    <RadioGroup
                        aria-labelledby="account-radio-buttons"
                        value={selectedAccount}
                        onChange={handleRadioChange}
                    >
                    {linkedAccounts.all.map((account, idx) => (
                        <FormControlLabel key={idx} value={account.CustomerID} control={<Radio/>} label={buildAddressToString(account)} />
                    ))}

                    </RadioGroup>
                </FormControl>

                <AccountFormPrimary
                    loading={authLoaders.setDefaultAccount || authLoaders.getDefaultAccount ? true : false}
                    disabled={authLoaders.setDefaultAccount || authLoaders.getDefaultAccount ? true : false}
                    onClick={() => submitDefaultAccount()}
                    variant="primary"
                    className="w-100 mt-2"
                >
                    Set Default
                </AccountFormPrimary>
            </Box>

        </Box>
    </Modal>
    )
}


const SwitchUserAccount = () => {

    const {open, handleToggle} = useToggleState();
    return (
        <>
            {open && <OpenedModal open handleToggle={handleToggle}/> }
            <Grid justifyContent="end" className="mb-3" container>
                <Box className="f-right mt-3">
                    <Fab onClick={handleToggle} variant="extended" size="medium" color="primary" aria-label="add">
                        <ChangeCircleIcon sx={{ mr: 1 }} />
                        Switch Account
                    </Fab>
                </Box>

            </Grid>
        </>
    )
}

export default SwitchUserAccount