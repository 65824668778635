const fontSize = {
    fontSize: {
        xs: 7,
        lg: 14
    }
}

export default function styles(){
    return {
        '& .MuiDataGrid-main' : {
            ...fontSize
        },
        '& .MuiCard-root' : {
            ...fontSize
        },
        '& .MuiTypography-body1' : {
            ...fontSize
        },
        '& .MuiPaper-root' : {
            ...fontSize
        },
        '& .MuiTableCell-root' : {
            ...fontSize
        },
        '& .MuiTableBody-root' : {
            ...fontSize
        },
        '& .MuiSwitch-switchBase': {
            height: '38px !important',
        },
        '& .MuiButton-sizeSmall': {
            fontSize: {
                xs: 7,
                md: '12px'
            },
            height: {
                xs: '30px',
                md: 'inherit'
            },
            padding: {
                xs: '6px',
                md: '7px 18px'

            }
        },
        '& .MuiButton-sizeMedium': {
            ...fontSize,
            height: {
                xs: '30px',
                md: 'fit-content'
            },
        },
        '& .MuiButtonBase-root': {
            '& .MuiButton-sizeMedium': {
                ...fontSize,
                height: {
                    xs: '30px',
                    md: 'auto'
                },
            }
            
        },
        '& .MuiGrid-root': {
            fontSize: {
                xs: 7,
                lg: 14
            }
        },
        '& .MuiCard-root': {
            fontSize: {
                xs: 7,
                lg: 14
            }
        },
        '& .MuiGrid-item': {
            ...fontSize
        },
        '& .MuiBox-root': {
            ...fontSize
        },
        '& .MuiPaper-root': {
            ...fontSize
        },
        '& .MuiPickersDay-root': {
            height: '36px',
            fontSize: {
                xs: 8,
                md: 10
            }
        },
        '& .MuiDateRangePickerDay-day': {
            height: '36px',
            fontSize: {
                xs: 8,
                md: 10
            }
        },
        '& .MuiDialog-root': {
            ...fontSize,
            '& .MuiButtonBase-root': {
                ...fontSize,
                height: {
                    xs: '0px',
                    md: '44.5px'
                },
            },
        },
        '& .MuiDialog-scrollPaper': {
            ...fontSize
        },
        '& .MuiModal-root': {
            ...fontSize
        },
        '& .MuiBackdrop-root': {
            ...fontSize
        },
        '& .MuiDialog-container': {
            ...fontSize
        },
        '& .MuiDialogContent-root': {
            ...fontSize
        },
        '& .MuiDialog-paper': {
            ...fontSize
        },
        '& .MuiInputBase-root': {
            ...fontSize
        },
        '& .MuiAlert-icon': {
            marginRight: {
                xs: '2px',
                md: '12px'
            }
        },
        '& .MuiAlert-root': {
            ...fontSize,
            '& .MuiAlert-message': {
                ...fontSize,
                '& .MuiTypography-root': {
                    ...fontSize,
                }
            }
        },
        '& .MuiChip-root': {
            '& .MuiChip-sizeMedium': {
                height: {
                    xs: '16px',
                    lg: '32px'
                },
                ...fontSize,
                '& .MuiChip-icon': {
                    fontSize: {
                        xs: 7,
                        lg: 20
                    }
                },
                '& .MuiChip-deleteIcon': {
                    fontSize: {
                        xs: 12,
                        lg: 20
                    }
                }
            },
            height: {
                xs: '16px',
                md: '26px'
            },
            fontSize: {
                xs: 7,
                md: 'inherit'
            },
            '& .MuiChip-icon': {
                fontSize: {
                    xs: 7,
                    md: 'inherit'
                }
            },
            '& .MuiChip-deleteIcon': {
                fontSize: {
                    xs: 12,
                    md: 'inherit'
                }
            }
        },
        '& .MuiSvgIcon-root': {
            fontSize: {
                xs: '13px',
                lg: '20px'
            }
        },
        '& .MuiChip-label': {
            ...fontSize,
            paddingLeft: {
                xs: '6px',
                lg: '6px'
            },
            paddingRight: {
                xs: '6px',
                lg: '6px'
            }
        },
    }
}