import React from 'react'
import { styled } from '@mui/material/styles';
import DefaultDialog from '@mui/material/Dialog';
import DefaultTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import crmSizes from '../../Setup/crmSizes';

const Dialog = styled(DefaultDialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function DialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DefaultTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DefaultTitle>
  );
}

const CustomDialog = (props) => {

    const {
        open,
        handleClose,
        crmStyles = true,
        title,
        withAlert = false,
        alertSeverity = "",
        children,

        titleSx = {},
        titleClasses = "",

        contentSx = {},
        contentClasses = "",

        actionsSx = {},
        actionsClasses = "",

        customActions = false,
        hasActions = true,

        sx = {},

        ...rest
    } = props;


    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby={`dialog-${title}`}
            open={open}
            fullWidth
            TransitionComponent={Transition}
            sx={{
              ...(crmStyles ? crmSizes() : null),
              ...sx,
              '& .MuiDialogContent-root': {
                ...contentSx
              }
            }}
            {...rest}

      >
        <DialogTitle 
            sx={{
              p: withAlert ? 0 : '16px',
              backgroundColor: 'primary.main',
              color: 'primary.contrastText',
              mb: 2,
              ...titleSx, 
          }} 
      
            className={titleClasses} 
            onClose={handleClose}
        >
            {withAlert ? (
              <Alert  
                sx={{
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0
                }}
                className="text-vertical" 
                severity={alertSeverity}>
                  <Typography className={`${titleClasses} vertical-auto`} component="h2" variant="h2">
                      {title}
                  </Typography>
              </Alert>

            ) : title}
        </DialogTitle>

        <DialogContent 
            sx={contentSx}
            className={contentClasses} 
        >
            {children}
        </DialogContent>
        
        {hasActions ? <DialogActions 
            sx={actionsSx} 
            className={actionsClasses}
        >
            {customActions ? ( customActions ) : (
              <Button 
                  color="error" 
                  variant="outlined" 
                  onClick={handleClose}
              >
                  Close
              </Button>
            )}
        </DialogActions> : null}


      </Dialog>
    )
}

export default CustomDialog