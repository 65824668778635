import Typography from '@mui/material/Typography'

const StyledBox = (props) => {

    const {
        className = "",
        children,
        ...rest
    } = props;

    return (
        <Typography 
            {...rest} 
            component={"div"} 
            className={`${className} pointer`}
            variant="styled-box"
        >
            {children} 
        </Typography>
    )
}

export default StyledBox