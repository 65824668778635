import { capitalizeFirstLetter } from '@components/config/helpers';
import useAuth from '@components/_hooks/useAuth';
import React, { Fragment } from 'react'
import { useSelector } from 'react-redux';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import SwitchUserAccount from '../Dashboard/SwitchUserAccount';
import Grid from '@mui/material/Grid';
import { Span } from '../Reusables/Typography/P';
import DefaultButton from '../Reusables/Theme/DefaultButton';


const BasicAccountDialog = () => {
    const user = useSelector(state => state.authReducer.user);
    const isAuthenticated = useSelector(state => state.authReducer.isAuthenticated);
    const discounts = user?.details?.discounts || false;

    const {handleLogout, openAccountModal} = useAuth();

    return (
        <div>

            <Grid container>
                <Grid item xs={8}>
                    <p>Hi {capitalizeFirstLetter(user.name)}, welcome to your account details.</p>
                    <p className='mb-1'>
                        {/* Your account currently has a discount plan offering {parseFloat(details.discount * 100)}% off all products. */}
                        See below for your list of active discounts.
                    </p>
                </Grid>
                <Grid item xs={4}>
                    {user?.has_default == 1 ? (
                        <SwitchUserAccount />
                    ) : (
                        <>
                            <DefaultButton variant="contained" color="primary" onClick={openAccountModal}>
                                <Span>Fill out your details</Span>
                            </DefaultButton>
                        </>
                    )}
                </Grid>
            </Grid>
          



            <TableContainer 
                className="w-60 mt-2"
                component={Paper} 
                elevation={1}
            >
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell width="50%">Discount Type</TableCell>
                            <TableCell width="50%" align="right">Discount (%)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            {discounts.map((discount, idx) => (
                                <Fragment key={idx}>
                                    <TableCell>{discount?.user_account_discount_name || 'N/A'}</TableCell>
                                    <TableCell className="text-end">{discount.discount_value != null ? (parseFloat(discount.discount_value) * 100).toFixed(2) : '0'}</TableCell>
                                </Fragment>
                            ))}
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            <br/>

            <span onClick={handleLogout} className="link pointer">Log out?</span>
        </div>
    )
}

export default BasicAccountDialog