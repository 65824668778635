import { styled } from '@mui/material/styles';
import MuiButton from '@mui/material/Button'

const Button = styled(MuiButton)({
    // transition: 'none',
    '&:hover': {
      transform: 'none',
    },
});
  
const DefaultButton = (props) => {
    const {
        children,
        ...rest
    } = props;

    return (
        <Button {...rest}>{children}</Button>
    )
}

export default DefaultButton